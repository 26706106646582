import React, { useEffect, useState } from "react"
import { handleKeyUp } from "../../utils"
import useHasMounted from "../../utils/useHasMounted"
import Grid from "../Grid/index.js"
import Header from "../Header/Header"
import MenuButton from "../Menu/MenuButton"
import IconSound from "../Symbols/Sound"
import IconSoundOff from "../Symbols/SoundOff"
import "./VisitingRoom.css"

const VisitingRoom = ({ loading, profiles = [], images, ...props }) => {
  const hasMounted = useHasMounted()
  const params = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )

  const [isShowBanner, setShowBanner] = useState(false)

  const [isSoundEnabled, setSoundEnabled] = useState(
    typeof window !== "undefined" &&
      window.localStorage.getItem("isSoundEnabled") === "false"
      ? false
      : true
  )

  useEffect(() => {
    if (isSoundEnabled) {
      window.localStorage.removeItem("isSoundEnabled")
    } else {
      window.localStorage.setItem("isSoundEnabled", "false")
    }
  }, [isSoundEnabled])

  if (!hasMounted) return null

  return (
    <div className="visiting-room-wrap container">
      <>
        {!loading && (
          <>
            <Header
              title="The Visiting Room"
              banner="VISITING-ROOM"
              onSetShowBanner={setShowBanner}
              setSoundEnabled={setSoundEnabled}
              isSoundEnabled={isSoundEnabled}
              actions={
                <MenuButton
                  onClick={() => {
                    setSoundEnabled(!isSoundEnabled)
                  }}
                  onKeyUp={(ev) =>
                    handleKeyUp(ev, () => {
                      setSoundEnabled(!isSoundEnabled)
                    })
                  }
                  buttonContent={
                    isSoundEnabled ? <IconSound /> : <IconSoundOff />
                  }
                  classes="button-sound-enabler"
                  tooltipContent={
                    <div style={{ width: isSoundEnabled ? "90px" : "85px" }}>
                      {isSoundEnabled ? "Disable sound" : "Enable sound"}
                    </div>
                  }
                />
              }
            />
            <Grid
              profiles={profiles}
              images={images}
              isLoadBackgrounds={!isShowBanner}
              isSoundEnabled={isSoundEnabled}
              setSoundEnabled={setSoundEnabled}
            />
          </>
        )}
      </>
    </div>
  )
}

export default VisitingRoom
