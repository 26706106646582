import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import getProfileProps from "../../utils/getProfileProps"
import GridCell from "./GridCell"
import GridCellGeneric from "./GridCellGeneric"
import { Link } from "gatsby"

const GridChunk = ({
  isLast,
  isSoundEnabled,
  profileChunk,
  profiles,
  setCurrentChunk,
  setSoundEnabled,
}) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    if (inView) {
      setCurrentChunk()
    }
  }, [inView]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <>
      <div ref={ref} className="grid">
        {profileChunk
          .map((node, idx) => getProfileProps(node))
          .map((props) => {
            const {
              image,
              fullName,
              quote,
              profile_picture,
              color,
              video_link,
            } = props

            return (
              <GridCell
                key={fullName.replace(/ /g, "_")}
                image={image}
                fullName={fullName}
                quote={quote}
                isSoundEnabled={isSoundEnabled}
                profile_picture={profile_picture}
                color={color}
                video_link={video_link}
                isLoadBackgrounds={inView}
                setSoundEnabled={setSoundEnabled}
              />
            )
          })}
        {isLast && (
          <>
            <GridCellGeneric
              image={getProfileProps(profiles[5]).image}
              brightness={0.7}
              classes={"vr md lg xl"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[7]).image}
              brightness={0.5}
              classes={"vr md  xl"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[12]).image}
              brightness={0.4}
              classes={"vr xl"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[2]).image}
              brightness={0.25}
              classes={"vr md lg xl"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[31]).image}
              brightness={0.18}
              classes={"vr lg xl"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[25]).image}
              brightness={0.1}
              classes={"vr md"}
            />
            <GridCellGeneric
              image={getProfileProps(profiles[11]).image}
              brightness={0.1}
              classes={"md lg xl"}
              message={
                <>
                  <p>
                    Nearly 5,000 people are serving life without parole in
                    Louisiana.
                  </p>

                  <p>
                    See more interviews in the{" "}
                    <Link to="/archive">Archive</Link>.
                  </p>
                </>
              }
            />
          </>
        )}
      </div>
      {isLast && (
        <div className="grid grid-generic">
          <GridCellGeneric
            image={getProfileProps(profiles[11]).image}
            brightness={0.1}
            classes={"md lg xl"}
            message={
              <>
                <p>
                  Nearly 5,000 people are serving life without parole in
                  Louisiana.
                </p>

                <p>
                  See more interviews in the <Link to="/archive">Archive</Link>.
                </p>
              </>
            }
          />
        </div>
      )}
    </>
  )
}

export default GridChunk
